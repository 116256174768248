/* eslint-disable no-unused-vars, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { ViewTypeComponent } from '@root/utils/constant';
import { NONREQUEST } from '@root/redux/requestState';
import cloneDeep from 'lodash/cloneDeep';
import actions from './actions';

const INITIAL_SITE = {
  loadingMenu: true,
  isUsersBoardReady: false,
  siteList: [],
  sites: [],
  spacesFetchState: NONREQUEST,
  projectFetchState: NONREQUEST,
  userManager: {
    removal: {
      isConfirmDialogShowing: false,
      user: {},
      siteId: '',
    },
    invitation: {
      user: {},
      siteId: '',
      state: '', // "not_invited", "inviting", "invited", "failed" "declined", "accepted"
    },
  },
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder
    .addCase(actions.setLoading, (state, action) => ({
      ...state,
      isLoading: action.payload,
    }))
    .addCase(actions.updateSite, (state, action) => {
      state.siteList = state.siteList.map((site) => {
        if (action.payload.id !== site.siteId) return site;
        const { name, description } = action.payload;
        return {
          ...site,
          name,
          description,
        };
      });
      return state;
    })
    .addCase(actions.setSites, (state, action) => {
      let siteList = [];
      if (Array.isArray(action.payload) && action.payload?.length > 0)
        siteList = action.payload.map((site) => ({
          ...site,
          label: site.name,
          value: site.siteId,
        }));
      return {
        ...state,
        siteList,
      };
    })
    .addCase(actions.setSiteItems, (state, action) => {
      const { values, siteId } = action.payload;
      const siteList = [...state.siteList];
      const siteIndex = siteList.findIndex((s) => s.siteId === siteId);
      siteList[siteIndex] = {
        ...siteList[siteIndex],
        items: values,
      };
      return {
        ...state,
        siteList,
      };
    })
    .addCase(actions.updateStateSites, (state, action) =>
      // state.siteList = action.payload;
      ({
        ...state,
        sites: action.payload,
      }),
    )
    .addCase(actions.updateStateLoadingMenu, (state, action) => ({
      ...state,
      loadingMenu: action.payload,
    }))
    .addCase(actions.updateStateSiteDetailItems, (state, action) => {
      const { projects, siteId } = action.payload;
      let site = {};
      const newSiteList = [...state.sites];
      site = newSiteList.find((s) => s.siteId === siteId);
      site = {
        ...site,
        projects,
      };
      return {
        ...state,
        site,
      };
    })
    .addCase(actions.updateStateSiteDetailSpaces, (state, action) => {
      const { spaces, siteId } = action.payload;
      let site = {};
      const newSiteList = [...state.sites];
      site = newSiteList.find((s) => s.siteId === siteId);
      site = {
        ...site,
        ...state.site,
        spaces,
      };
      return {
        ...state,
        site,
      };
    })
    .addCase(actions.updateStateSiteDetailSpacesFetchState, (state, action) => {
      const { fetchState } = action.payload;
      return {
        ...state,
        spacesFetchState: fetchState,
      };
    })
    // update detail data for each projects, each spaces in site
    .addCase(actions.updateStateSiteItemDetailData, (state, action) => {
      const { detailData, projectKey, siteId } = action.payload;
      const projects = cloneDeep(state.site.projects);
      const spaces = cloneDeep(state.site.spaces);
      // let spaceItemDataLoading = { ...state.spacesFetchState };
      const projectItem = (projects || []).find((s) => s.key === projectKey);
      const spaceItem = (spaces || []).find((s) => s.key === projectKey);
      if (projectItem && detailData.project) {
        projectItem.detail = detailData.project;
        projectItem.isLoading = false;
      }
      if (spaceItem && detailData.space) {
        spaceItem.detail = detailData.space;
        spaceItem.isLoading = false;
      }

      return {
        ...state,
        site: {
          ...state.site,
          spaces,
          projects,
        },
      };
    })
    .addCase(actions.getDetailItemDataForSpaces, (state, action) => ({
      ...state,
    }))
    .addCase(actions.getDetailItemDataForProject, (state, action) => ({
      ...state,
    }))
    .addCase(actions.updateDetailItemDataForProject, (state, action) => ({
      ...state,
    }))
    .addCase(actions.updateOrganizationIdForSiteListState, (state, action) => {
      const { sites, orgId, organizationName } = action.payload;
      let siteList = cloneDeep(state.siteList);
      siteList = siteList.map((site) => {
        if (sites.includes(site.url))
          return {
            ...site,
            organization_id: orgId,
            organizationName,
          };
        return site;
      });
      return {
        ...state,
        siteList,
      };
    })
    .addCase(
      actions.updateCheckNewUserManagementForSiteList,
      (state, action) => {
        // orgs array contain organization_id and isNewUserManagement field
        const orgs = action.payload;
        let siteList = cloneDeep(state.siteList);
        if (Array.isArray(orgs)) {
          siteList = siteList.map((site) => {
            const org = orgs.find(
              (org) => org.organization_id === site.organizationId,
            );
            if (org)
              return {
                ...site,
                isNewUserManagement: org.isNewUserManagement,
              };
            return site;
          });
        }

        return {
          ...state,
          siteList,
        };
      },
    );
});
