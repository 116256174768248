import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ViewTypeComponent } from '@root/utils/constant';
import { ALERT_TYPE } from '../../components/common/MessageSnackbar';
import { siteAPIs } from '../../services';
import atlasAPIs from '../../services/atlasApis';
import { isNotEmptyArray } from '../../utils';
import * as appTypes from '../app/types';
import actions from './actions';
import * as types from './types';
import { REQUESTING, REQUESTSUCCESS } from '../requestState';

const mergeData = (prjs, spaces, siteLinks = []) => {
  const projectSpaces = [];
  (prjs || []).forEach((p) => {
    const key = p.key.toUpperCase();
    projectSpaces.push({
      key,
      project: p,
      isPublic: p.isPublic,
    });
  });

  if (!spaces.length && prjs.length && isNotEmptyArray(siteLinks)) {
    let projectByLink = null;
    siteLinks.forEach((siteLink) => {
      projectByLink = siteLink
        ? projectSpaces.find(
            (item) => siteLink?.projectKey === item?.project?.key,
          )
        : null;
      if (projectByLink) {
        projectByLink.linkId = siteLink?.linkId;
        projectByLink.nameLink = siteLink?.nameLink;
        projectByLink.siteId = siteLink?.siteId;
      }
    });
  }
  if (spaces.length && isNotEmptyArray(siteLinks)) {
    (spaces || []).forEach((sp) => {
      // eslint-disable-next-line no-param-reassign
      sp.key = sp.key.toUpperCase();
      const { key } = sp;
      let projectByLink = null;
      siteLinks.forEach((siteLink) => {
        projectByLink = siteLink
          ? projectSpaces.find(
              (item) => siteLink?.projectKey === item?.project?.key,
            )
          : null;
        if (projectByLink) {
          if (siteLink.spaceKey === null) {
            projectByLink.space = null;
          }
          if (siteLink.spaceKey === key) {
            projectByLink.space = sp;
          }
          projectByLink.linkId = siteLink?.linkId;
          projectByLink.nameLink = siteLink?.nameLink;
          projectByLink.siteId = siteLink?.siteId;
        }
        const project = projectSpaces.find((ps) => ps?.key === key);
        if (project && !project.space) {
          project.space = sp;
        }
      });
    });
  }
  (spaces || []).forEach((p) => {
    const space = projectSpaces?.filter((ps) => ps?.space?.key === p?.key);
    if (space.length === 0) {
      const obj = {
        key: p.key,
        space: p,
      };
      if (isNotEmptyArray(siteLinks))
        siteLinks.forEach((siteLink) => {
          if (siteLink.spaceKey === p.key) {
            obj.linkId = siteLink?.linkId;
            obj.nameLink = siteLink?.nameLink;
            obj.siteId = siteLink?.siteId;
          }
        });
      const space2 = projectSpaces.find(
        (ps2) => ps2?.project?.key === p?.key && !ps2?.linkId,
      );
      if (space2) {
        space2.space = p;
      } else {
        projectSpaces.push(obj);
      }
    }
  });
  return projectSpaces;
};

const getAllGroup = async (siteId) => {
  const opts = {
    headers: {
      siteid: siteId,
      relayapi: '/rest/api/3/groups/picker',
      relaymethod: 'GET',
    },
  };
  const raw = await atlasAPIs.proxyApi(opts);
  const { groups } = raw.data;
  return groups;
};

const getAllProjects = async (siteId) => {
  let projects = [];
  try {
    const response = await siteAPIs.getAllProjects({ siteId });
    projects = response.data;
  } catch (e) {
    //
  }
  return projects;
};

const getAllSpaces = async (siteId, viewTypeComponent) => {
  let spaces = [];
  let isEnd = false;
  const permissionQuery =
    viewTypeComponent === ViewTypeComponent.home ? '' : 'permissions,';

  while (!isEnd) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const raw = await atlasAPIs.proxyApi({
        headers: {
          siteid: siteId,
          relayapi: `/wiki/rest/api/space/?limit=50&expand=${permissionQuery}icon&start=${spaces.length}`,
          relaymethod: 'GET',
        },
      });
      // debugger;
      if (raw.data?.results) spaces = spaces.concat(raw.data.results);
      // eslint-disable-next-line no-underscore-dangle
      isEnd = !raw.data._links.next;
    } catch (error) {
      isEnd = true;
    }
  }
  return spaces;
};

function* getSite(site, viewTypeComponent) {
  const { siteId, isJiraEnabled, isConfluenceEnabled } = site;
  if (viewTypeComponent === ViewTypeComponent.home) {
    // don't get site detail
    yield put(actions.updateStateLoadingMenu(false));
  } else {
    const getSiteUsersEffect =
      viewTypeComponent === ViewTypeComponent.home
        ? null
        : call(siteAPIs.getSiteUsers, siteId);
    const getAllJiraGroupsEffect =
      viewTypeComponent === ViewTypeComponent.home
        ? null
        : call(getAllGroup, siteId); // if jira is enabled
    const getAllJiraProjectsEffect = call(getAllProjects, siteId); // if jira is enabled
    const getAllConfluenceSpacesEffect = call(
      getAllSpaces,
      siteId,
      viewTypeComponent,
    ); // if confluence is enabled
    const getAllSiteLinksEffect = call(siteAPIs.getAllSiteLinks, siteId);
    const effects = [getSiteUsersEffect, getAllSiteLinksEffect];
    if (isJiraEnabled) {
      effects.push(getAllJiraGroupsEffect);
      effects.push(getAllJiraProjectsEffect);
    }
    if (isConfluenceEnabled) {
      effects.push(getAllConfluenceSpacesEffect);
    }

    const res = yield all(effects);
    const siteUsersRes = res[0];
    const siteLinksRes = res[1];
    const jiraGroupsRes = isJiraEnabled ? res[2] : undefined;
    const jiraProjectsRes = isJiraEnabled ? res[3] : undefined;

    // eslint-disable-next-line no-nested-ternary
    const confluenceSpacesRes =
      // eslint-disable-next-line no-nested-ternary
      isJiraEnabled ? res[4] : isConfluenceEnabled ? res[2] : undefined;
    // set site users
    const siteUsers = [];
    if (siteUsersRes) {
      const users = siteUsersRes?.data?.siteUsers || [];
      siteUsers.push(...users);
    }
    yield put(
      actions.loadSiteUsers({ values: siteUsers, siteId, viewTypeComponent }),
    );

    // set jira groups
    yield put(
      actions.setSiteGroups({
        values: jiraGroupsRes || [],
        siteId,
      }),
    );

    // set jira projects and/or confluence spaces
    const projectsAndSpaces = mergeData(
      jiraProjectsRes?.data || [],
      confluenceSpacesRes || [],
      siteLinksRes.data || [],
    );
    yield put(actions.setSiteItems({ values: projectsAndSpaces, siteId }));
  }
}

export function* getSitesSaga() {
  yield takeLatest(
    types.GET_SITES_SAGA,
    function* getSites({ payload: params }) {
      const viewTypeComponent =
        params && params.viewTypeComponent ? params.viewTypeComponent : '';
      try {
        yield put({ type: types.USER_BOARD_STATE, payload: false });
        const response = yield call(siteAPIs.getAll);
        const sites = response?.data?.raw || [];
        // const sites = yield call(formatSitesData, response?.data?.raw);
        yield put(actions.setSites(sites));
        yield put(actions.updateStateSites(sites)); // using in security page
        const { siteList } = yield select((state) => state.site);
        yield all(
          siteList.map((site) => call(getSite, site, viewTypeComponent)),
        );
        yield put({ type: types.USER_BOARD_STATE, payload: true });
      } catch (error) {
        yield put({ type: types.SITE_PROCESSING, payload: false });
        yield put({ type: types.USER_BOARD_STATE, payload: false });
      }
    },
  );
}
export function* updateSiteSaga() {
  yield takeLatest(types.UPDATE_SITE_SAGA, function* updateSite({ payload }) {
    try {
      yield put({ type: types.SITE_PROCESSING, payload: true });
      yield call(siteAPIs.edit, payload);
      yield put(actions.getSites());
      yield put({ type: types.SITE_PROCESSING, payload: false });
    } catch (error) {
      yield put({ type: types.SITE_PROCESSING, payload: false });
      yield put({
        type: appTypes.OPEN_SNACK_BAR,
        payload: {
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.somethingWentWrong',
        },
      });
    }
  });
}

export function* deleteSiteSaga() {
  yield takeLatest(types.DELETE_SITE_SAGA, function* deleteSite({ payload }) {
    try {
      yield put({ type: types.SITE_PROCESSING, payload: true });
      yield call(siteAPIs.delete, payload);
      yield put(actions.getSites());
      yield put({ type: types.SITE_PROCESSING, payload: false });
    } catch (error) {
      yield put({ type: types.SITE_PROCESSING, payload: false });
      yield put({
        type: appTypes.OPEN_SNACK_BAR,
        payload: {
          open: true,
          type: ALERT_TYPE.error,
          messageKey: 'snackbar.somethingWentWrong',
        },
      });
    }
  });
}

export function* getSiteListSaga() {
  yield takeLatest(
    types.SITE_REQUESTAPI_GETLIST,
    function* getSiteList({ payload: queryParams }) {
      yield put(actions.updateStateLoadingMenu(true));
      const response = yield siteAPIs.getAll(queryParams);
      if (response?.data?.raw) {
        const sites = response?.data?.raw;
        yield put(actions.updateStateSites(sites));
        yield put(actions.setSites(sites));
        yield put(actions.updateStateLoadingMenu(false));
      } else {
        yield put(actions.updateStateLoadingMenu(false));
        yield put({
          type: appTypes.OPEN_SNACK_BAR,
          payload: {
            open: true,
            type: ALERT_TYPE.error,
            messageKey: 'snackbar.somethingWentWrong',
          },
        });
      }
    },
  );
}

function* getSiteDetailprojectsAndSpacesBySiteId(action) {
  const { siteId } = action.payload;
  yield put(actions.updateStateSiteDetailFetchState(REQUESTING));
  yield put(
    actions.updateStateSiteDetailItems({
      siteId,
    }),
  );
  yield put(actions.updateStateSiteDetailFetchState(REQUESTSUCCESS));
}

export function* getSiteDetailprojectsAndSpacesBySiteIdSaga() {
  yield takeLatest(
    types.SITE_REQUESTAPI_GET_SITE_DETAIL_ITEMS,
    getSiteDetailprojectsAndSpacesBySiteId,
  );
}

export function* siteSaga() {
  yield all([]);
}
export default siteSaga;
